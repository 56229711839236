<template>
    <v-card elevation="2" class="rounded-lg" dark :color="styleCicle">
              <v-card-text class="d-flex justify-space-between align-center">
                <div>
                  <strong>{{cardTitle}}</strong>
                </div>


                <span class="text-h3 font-weight-medium">{{ result }}</span>
              </v-card-text>
            </v-card>
  
</template>

<script>

export default {
    name: "CardEstadistica",
    props: {
    result: {
      type: Number,
      default:0,
    },
    styleCicle:{
        type:String,
        default:"primary"
    },
    cardTitle:{
        type:String,
        default:"Sin resultados"
    }}

}
</script>

<style>

</style>