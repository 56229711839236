import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import Usuarios from '../views/UsersView.vue'
import Indice from '../views/IndiceView.vue'
import Asistencias from '../views/AsistenciasView.vue'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/usuarios',
    name: 'usuarios',
    component: Usuarios
  },
  {
    path: '/indice',
    name: 'Indice',
    component: Indice
  },
  {
    path: '/asistencias',
    name: 'asistencias',
    component: Asistencias
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/policia/:id',
    name: 'policiaEdit',
    component: () => import(/* webpackChunkName: "about" */ '../views/parametros/add-edit/policia.vue')
  },
  {
    path: '/usuario/:id',
    name: 'usuario',
    component: () => import(/* webpackChunkName: "about" */ '../views/parametros/add-edit/usuario.vue')
  },
  {
    path: '/indice/detalle/:id',
    name: 'detallePolicia',
    component: () => import(/* webpackChunkName: "about" */ '../views/DetallePoliciaView.vue')
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/Login.vue"),

    
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
