<template>
  <div>
    <v-row>
      <v-col
        md="7"
        class="d-flex justify-center align-center"
        v-if="this.$vuetify.breakpoint.smAndUp"
        style="background: #f4f5fa; height: 100vh"
      >
        <lottie :width="600" :options="defaultOptions" />
      </v-col>
      <v-col
        style="background: white"
        cols="12"
        md="5"
        class="d-flex justify-center align-center"
      >
        <v-card class="mx-auto v-card--flat cardLogin" style="max-width: 500px">
          <v-card-text>
            <h5 class="text-h5 font-weight-semibold mb-1">
              Direccion Personal D-1
            </h5>
            <p class="mb-0">Por favor para continuar inicie sesion</p>
          </v-card-text>
          <v-img
            class="white--text align-end logo"
            height="120px"
            src="../assets/logo1.png"
            style="object-fit: cover"
          >
          </v-img>
          <v-card-text>
            <v-form ref="form" action v-model="valid" lazy-validation>
              <v-card-text>
                <v-text-field
                  v-model="username"
                  :rules="nameRules"
                  label="Usuario"
                  prepend-inner-icon="mdi-account"
                  required
                ></v-text-field>

                <v-text-field
                  v-model="password"
                  :type="verContraseña ? 'text' : 'password'"
                  :rules="ReglasContraseña"
                  label="Contraseña"
                  prepend-inner-icon="mdi-key"
                  :append-icon="verContraseña ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="verContraseña = !verContraseña"
                  required
                ></v-text-field>
              </v-card-text>

              <v-card-actions class="justify-center">
                <v-btn
                  rounded
                  :loading="loading"
                  :disabled="loading"
                  block
                  color="primary"
                  @click="submit"
                >
                  <v-icon left> mdi-email-arrow-right </v-icon>
                  Enviar
                </v-btn>
              </v-card-actions>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import * as animationData from "../assets/lottiesfile/logind.json";

export default {
  data() {
    return {
      defaultOptions: {
        animationData: animationData.default,
      },
      loading: false,
      valid: false,
      verContraseña: false,
      password: "",
      username: "",
      nameRules: [
        (v) => !!v || "Ingresar Usuario",
        //(v) => v.length <= 10 || "El usuario debe contener minimo 10",
        // (v) =>  /(\d)/.test(v) || "debe tener un numero"
      ],
      ReglasContraseña: [
        (v) => !!v || "Ingresar contraseña",
        // (v) => v.length <= 3 || "La contraseña debe contener minimo 4 caracteres",
        // (v) => /([A-Z])/.test(v)  || "Debe contener una mayuscula",
      ],
    };
  },
  methods: {
    async submit() {
      if (this.$refs.form.validate()) {
        this.loading = true;

        try {
          await this.$store.dispatch("user/login", {
            user: this.username,
            password: this.password,
          });
        } catch (e) {
          console.log(">>>>cat", e);
        } finally {
          this.loading = false;
        }
      }
    },
  },
};
</script>

<style scoped>
img {
  width: 100%;
}
@media (max-width: 600px) {
  .logo {
    width: 320px;
    height: 85px !important;
  }
  .cardLogin {
    margin-top: 30px;
  }
}
</style>
