<template>
  <div>
    <v-subheader class="d-flex justify-space-between aling-center">
      <h2>Lista de Alumnos</h2>
    </v-subheader>
    <v-row>
      <v-col lg="7" cols="12">
        <v-row>
          <v-col lg="6" cols="12">
            <CardEstadistica
              :result="cadetesLength"
              cardTitle="Total Alumnos TECNICATURA SEG. CIUDADANA"
              styleCicle="green lighten-2"
            />
          </v-col>

          <v-col lg="6" cols="12">
            <CardEstadistica
              :result="aspitantesLength"
              cardTitle="Total Alumnos DIPLOMATURA SEG. CIUDADANA"
              styleCicle="blue"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="8">
        <Table
          title="Asistencias"
          :headers="headers"
          :dataForTable="alumnos.alumnos"
          :id="'id_alumno'"
          :urlFront="'alumno'"
          :urlApi="'/alumnos/alumnos'"
        />
      </v-col>
      <v-col cols="12" sm="4">
        <DownloadExcelVue :urlApiExcel="urlApiExcel"/>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState } from "vuex";

import CardEstadistica from "@/components/Cards/CardEstadistica.vue";
import Table from "@/components/datatables/Table.vue";
import DownloadExcelVue from '@/components/downloadExel/DownloadExcel.vue';

export default {
  async created() {
    // this.get();
    await this.$store.dispatch("d6/getAlumnos");
    this.alumnosLenght();
  },
  components: {
    CardEstadistica,
    Table,
    DownloadExcelVue
  },
  data() {
    return {
      urlApiExcel:'/alumnos/export_excel',
      dialog__delete: false,
      idDelete: "",
      cadetesLength: 0,
      aspitantesLength: 0,
      headers: [
        {
          text: "Apellido",
          align: "start",
          sortable: false,
          value: "apellido",
        },
        { text: "Nombre", value: "nombre" },
        { text: "D.N.I", value: "dni" },
        { text: "Comision", value: "Comision.comision" },
        { text: "Carrera", value: "Carrera.carrera" },
        { text: "Promocion", value: "promocion" },
        { text: "Grupo Sanguineo", value: "grupo_sanguineo" },
        { text: "Estado", value: "activo" },
        { text: "Acciones", value: "acciones" },
      ],
    };
  },
  methods: {
    alumnosLenght() {
      if (this.alumnos.alumnos) {
        const lengths = this.alumnos.alumnos.reduce(
          (acc, alumno) => {
            if (alumno.Carrera.carrera === "TECNICATURA SEG. CIUDADANA") {
              acc.cadetesLength++;
            } else if (
              alumno.Carrera.carrera === "DIPLOMATURA SEG. CIUDADANA"
            ) {
              acc.aspitantesLength++;
            }
            return acc;
          },
          { cadetesLength: 0, aspitantesLength: 0 }
        );
        this.cadetesLength = lengths.cadetesLength;
        this.aspitantesLength = lengths.aspitantesLength;

        return lengths;
      }
    },
  },
  computed: mapState({
    alumnos: (state) => state.d6.alumnos,
  }),
};
</script>

<style scoped>
.h3__list {
  height: 100%;
  display: flex;
  align-items: center;
}
</style>
