<template>
  <div>
    <v-subheader class="d-flex justify-space-between aling-center">
      <h2>Lista Policial</h2>
      <v-btn
        color="success"
        class="rounded-lg"
        @click="$router.push('/policia/add').catch((err) => {})"
      >
        Nuevo Registro
      </v-btn>
    </v-subheader>
    <v-row>
      <v-col lg="7" cols="12">
        <!-- <v-row>
          <v-col lg="6" cols="12">
            <CardEstadistica
              :result="cadetesLength"
              cardTitle="Total Policias Activos"
              styleCicle="green lighten-2"
            />
          </v-col>

          <v-col lg="6" cols="12">
            <CardEstadistica
              :result="aspitantesLength"
              cardTitle="Total Policias Inactivos"
              styleCicle="red darken-1"
            />
          </v-col>
        </v-row> -->
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <Table
          title="Indice Digital"
          :headers="headers"
          :dataForTable="policias"
          :id="'id_personal'"
          :urlFront="'policia'"
          :urlGoView="'detalle'"
          :urlApi="'/'"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState } from "vuex";

import CardEstadistica from "@/components/Cards/CardEstadistica.vue";
import Table from "@/components/datatables/Table.vue";

export default {
  async created() {
    // this.get();
    await this.$store.dispatch("d1/getDataPolice");
    // this.alumnosLenght();
  },
  components: {
    CardEstadistica,
    Table,
  },
  data() {
    return {
      urlValue: "",
      dialog__delete: false,
      idDelete: "",
      cadetesLength: 0,
      aspitantesLength: 0,
      headers: [
        {
          text: "ID",
          align: "start",
          sortable: false,
          value: "id_personal",
        },
        { text: "D.N.I", value: "dni", sortable: false },

        { text: "Jerarquia", value: "jerarquia", sortable: false },
        {
          text: "Apellido y Nombre",
          value: "nombre_completo",
          sortable: false,
        },
        { text: "Destino", value: "destino", sortable: false },

        { text: "Acciones", value: "acciones", sortable: false },
      ],
    };
  },
  methods: {
    // get() {
    //   const {
    //     params: { tipo },
    //   } = this.$route;
    //   this.urlValue = tipo;
    //   // console.log(`set${this.urlValue}`);
    // },
    // alumnosLenght() {
    //   if (this.alumnos.alumnos) {
    //     const lengths = this.alumnos.alumnos.reduce(
    //       (acc, alumno) => {
    //         if (alumno.Carrera.carrera === "TECNICATURA SEG. CIUDADANA") {
    //           acc.cadetesLength++;
    //         } else if (
    //           alumno.Carrera.carrera === "DIPLOMATURA SEG. CIUDADANA"
    //         ) {
    //           acc.aspitantesLength++;
    //         }
    //         return acc;
    //       },
    //       { cadetesLength: 0, aspitantesLength: 0 }
    //     );
    //     this.cadetesLength = lengths.cadetesLength;
    //     this.aspitantesLength = lengths.aspitantesLength;
    //     return lengths;
    //   }
    // },
  },
  computed: mapState({
    policias: (state) => state.d1.dataPolice,
  }),
  mounted() {
    console.log("pol", this.policias);
  },
};
</script>

<style scoped>
.h3__list {
  height: 100%;
  display: flex;
  align-items: center;
}
</style>
