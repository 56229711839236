<template>
  <div>
    <v-app-bar app color="#f6f6f6" elevate-on-scroll elevation="4">
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-spacer></v-spacer>

      <!-- <v-toolbar-title>Title</v-toolbar-title> -->

      <v-spacer></v-spacer>

      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <span
            v-bind="attrs"
            v-on="on"
            style="cursor: pointer"
            class="mx-5 mr-10"
          >
            <v-chip link>
              <v-badge dot bottom offset-y="10" offset-x="10" color="green">
                <v-avatar size="40">
                  <v-img
                    src="https://www.labsaenzrenauld.com/wp-content/uploads/2020/10/Perfil-hombre-ba%CC%81sico_738242395.jpg"
                  />
                </v-avatar>
              </v-badge>
              <!-- <span>{{ datosUser.nombre }}</span> -->
              <span>{{ userData.nombre_completo }}</span>
            </v-chip>
          </span>
        </template>
        <v-list width="250" class="py-0">
          <v-list-item two-line>
            <v-list-item-avatar>
              <v-img
                width="50"
                src="https://www.labsaenzrenauld.com/wp-content/uploads/2020/10/Perfil-hombre-ba%CC%81sico_738242395.jpg"
              />
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>
                {{ userData.nombre_completo }}
              </v-list-item-title>
              <v-list-item-subtitle> En Linea </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item
            v-for="{ icon, title } in menus"
            :key="icon"
            link
            @click="logout()"
          >
            <v-list-item-icon>
              <v-icon>{{ icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>

    <!--! SIDEBAR -->

    <v-navigation-drawer v-model="drawer" app>
      <v-img class="pa-4" src="@/assets/inicio.png">
        <div class="text-center mt-4"></div>
      </v-img>

      <v-divider></v-divider>
      <v-list>
        <v-list-item-group v-model="group">
          <div v-for="[icon, text, link] in links" :key="icon">
            <v-list-item link @click="$router.push(link).catch((err) => {})">
              <v-list-item-icon>
                <v-icon>{{ icon }}</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{ text }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </div>
        </v-list-item-group>
      </v-list>

      <!-- <v-list>
        <v-list-item-group v-model="group">
          <div v-for="{icono, menu, ruta} in links" :key="icono">
            <v-list-item  link  @click="$router.push(ruta).catch(err => { })">
            <v-list-item-icon>
              <v-icon>{{ icono }}</v-icon>
            </v-list-item-icon>
  
            <v-list-item-content>
              <v-list-item-title>{{ menu }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          </div>
        </v-list-item-group>
      </v-list> -->
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  mounted() {
    console.log("datos usuario", this.userData);
  },
  data() {
    return {
      links: [
        ["mdi-inbox-arrow-down", "Inicio", "/", 1],
        // ["mdi-application-cog-outline", "Parametros", "/parametros", 1],
        ["mdi-account-multiple", "Usuarios", "/usuarios", 1],
        // ["mdi-text-box-edit-outline", "Asistencias", "/asistencias", 1],
        ["mdi-clipboard-list-outline", "Indice", "/indice", 1],
      ],
      group: null,
      drawer: null,

      menus: [{ title: "Salir", icon: "mdi-logout" }],
    };
  },
  methods: {
    logout() {
      this.$store.dispatch("user/logout");
    },
  },
  computed: mapState({
    userData: (state) => state.user.userData,
  }),
};
</script>

<style lang="scss" scoped></style>
