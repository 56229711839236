<template>
  <div class="admin">
    <v-subheader class="d-flex justify-space-between aling-center">
      <h2>Bienvenidos</h2>
      <!-- <v-btn color="success" class="rounded-lg"> Pedidos </v-btn> -->
    </v-subheader>
    <v-row>
      <v-col cols="12">
        <v-alert dense text type="success">
          <strong>Ingreso Autorizado</strong>
        </v-alert>

        <v-row>
          <v-col cols="12" md="5">
            <v-card
              elevation="2"
              class="d-flex justify-space-between align-center rounded-lg overflow-hidden"
            >
              <v-card-title>
                {{ obtenerSaludo() }} <br />
                <!-- {{ datosUser.nombre }} -->
              </v-card-title>

              <div>
                <div class="bubble-shape-sm-secondary bubble-secondary"></div>
                <v-avatar class="ma-3" size="100" tile>
                  <v-icon size="100" color="white">mdi-account-check</v-icon>
                </v-avatar>
              </div>
            </v-card>
          </v-col>
          <v-col cols="12" md="7">
            <v-card class="mx-auto" >
              <v-card-title>
                <v-icon large left> mdi-monitor-cellphone </v-icon>
                <span class="text-h6 font-weight-boldt">Asistencias 2023</span>
              </v-card-title>

              <v-card-text class="text-h5 font-weight-bold">
                "Por consultas o reportes de errores con el funcionamiento del
                sistema por favor comunicarse al 2645202530"
              </v-card-text>

              <v-card-actions>
                <v-list-item class="grow">
                  <v-list-item-avatar color="grey darken-3">
                    <v-img
                      class="elevation-6"
                      alt=""
                      src="https://avataaars.io/?avatarStyle=Transparent&topType=ShortHairShortCurly&accessoriesType=Prescription02&hairColor=Black&facialHairType=Blank&clotheType=Hoodie&clotheColor=White&eyeType=Default&eyebrowType=DefaultNatural&mouthType=Default&skinColor=Light"
                    ></v-img>
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title>Cabo 1° Esquivel Leonardo</v-list-item-title>
                    <small>Area Sistemas</small>
                  </v-list-item-content>

                 
                </v-list-item>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "Inicio",

  data() {
    return {
      datosUser: "",
    };
  },

  mounted() {
    this.datosUser = JSON.parse(localStorage.getItem("token"));
  },
  methods: {
    obtenerSaludo() {
      const hora = new Date().getHours();
      if (hora >= 5 && hora < 14) {
        return "Buenos día!";
      } else if (hora >= 14 && hora < 20) {
        return "Buenas tardes!";
      } else {
        return "Buenas noches!";
      }
    },
  },
};
</script>

<style>
.bubble-shape-sm.bubble-primary:before {
  background: linear-gradient(
    140.9deg,
    rgb(227, 242, 253) -14.02%,
    rgba(21, 101, 192, 0) 77.58%
  );
}
.bubble-shape-sm.bubble-primary:after {
  background: linear-gradient(
    210.04deg,
    rgb(227, 242, 253) -50.94%,
    rgba(21, 101, 192, 0) 83.49%
  );
}

.bubble-shape-sm-secondary.bubble-secondary:before {
  background: linear-gradient(
    98deg,
    rgb(7, 73, 255) -14.02%,
    rgba(144, 202, 249, 0) 77.58%
  );
}
.bubble-shape-sm-secondary.bubble-secondary:after {
  background: linear-gradient(
    393deg,
    rgb(7, 73, 255) -50.94%,
    rgba(144, 202, 249, 0) 83.49%
  );
}

.bubble-shape-sm-secondary:before {
  content: "";
  position: absolute;
  width: 230px;
  height: 230px;
  border-radius: 50%;
  top: -160px;
  right: -30px;
}
.bubble-shape-sm-secondary:after {
  content: "";
  position: absolute;
  width: 210px;
  height: 210px;
  border-radius: 50%;
  top: -57px;
  right: -95px;
}
</style>

