<template>
  <div>
    <v-card class="mt-10">
      <v-card-title>
        <v-row>
          <v-col cols="12" md="6">
            <div class="h3__list">
              <h3>{{ title }}</h3>
            </div>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Busqueda rapida"
              single-line
              hide-details
            >
            </v-text-field>
          </v-col>
        </v-row>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="dataForTable"
        :search="search"
        :items-per-page="10"
      >
        <template v-slot:[`item.activo`]="slotProps">
          <v-chip
            :color="slotProps.item.activo == 1 ? 'green' : 'red'"
            outlined
          >
            {{ slotProps.item.activo === 1 ? "Habilitado" : "Suspendido" }}
          </v-chip>
        </template>

        <template v-slot:[`item.acciones`]="slotProps">
          <ActionsMenuTable
            :dataActions="slotProps.item"
            :urlGoViewInfo="urlGoView"
            :idGo="id"
          />
        </template>

        <!-- <template v-slot:[`item.acciones`]="slotProps">
          <v-icon
            color="green"
            class="ml-2"
            text
            @click="goToEditMode(slotProps.item)"
            >mdi-pencil</v-icon
          >
          <v-icon
            color="red"
            class="ml-2"
            text
            @click="openDelete(slotProps.item)"
            >mdi-delete</v-icon
          >
        </template> -->
      </v-data-table>
    </v-card>

    <CardDelete
      :open="open"
      :options="options"
      @close="(value) => (open = value)"
    />
  </div>
</template>

<script>
import CardDelete from "../Cards/CardDelete.vue";
import ActionsMenuTable from "../datatables/ActiosMenuTable.vue";
export default {
  name: "DatatableComponent",

  components: {
    CardDelete,
    ActionsMenuTable,
  },
  props: {
    title: {
      type: String,
      default: " ",
    },

    headers: {
      type: Array,
    },
    dataForTable: {
      type: Array,
    },
    urlApi: {
      type: String,
      default: "",
    },
    urlFront: {
      type: String,
      default: "",
    },
    urlGoView: {
      type: String,
      default: "",
    },
    id: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      search: "",
      open: false,
      options: {},
    };
  },

  methods: {
    goToEditMode(data) {
      this.$router.push(`/${this.urlFront}/${data[this.id]}`);
    },
    openDelete(data) {
      this.open = true;
      this.options = {
        url: this.urlApi,
        data: data[this.id],
      };
    },
  },
};
</script>

<style lang="scss" scoped></style>
