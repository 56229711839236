<template>
  <div>
    <v-subheader class="d-flex justify-space-between aling-center">
      <h2>Lista de Usuarios</h2>
      <v-btn
          color="success"
          class="rounded-lg"
          @click="$router.push('/usuario/add').catch((err) => {})"
        >
          Crear Usuario
        </v-btn>
    </v-subheader>
    <v-row>
      <v-col lg="7" cols="12">
        <v-row>
          <v-col lg="6" cols="12">
            <CardEstadistica
              :result="cadetesLength"
              cardTitle="Total de Usuarios Activos"
              styleCicle="green lighten-2"
            />
          </v-col>

          <v-col lg="6" cols="12">
            <CardEstadistica
              :result="aspitantesLength"
              cardTitle="Total de Usuarios Suspendidos "
              styleCicle="red"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <Table
          title="Listado general de Usuarios"
          :headers="headers"
          :dataForTable="usuarios"
          :id="'id_usuario'"
          :urlFront="'usuario'"
          :urlApi = "'/usuarios/usuarios'"
        />
      </v-col>
    </v-row>
    
      
  </div>
</template>

<script>
import { mapState } from "vuex";

import CardEstadistica from "@/components/Cards/CardEstadistica.vue";
import Table from "@/components/datatables/Table.vue";

export default {
  async created() {
    // this.get();
    await this.$store.dispatch("d6/getUsuarios");
    // this.alumnosLenght()
   
  },
  components: {
    CardEstadistica,
    Table,
  },
  data() {
    return {
      urlValue: "",
      cadetesLength: 0,
      aspitantesLength: 0,
      headers: [
        {
          text: "Nombre y Apellido",
          align: "start",
          sortable: false,
          value: "nombre",
        },
        { text: "D.N.I", value: "dni" },
        { text: "Direccion", value: "direccion" },
        { text: "telefono", value: "telefono" },
        { text: "Rol", value: "id_rol" },
        { text: "Estado", value: "activo" },
        { text: "Acciones", value: "acciones" },
      ],
    };
  },
  methods: {
    // get() {
    //   const {
    //     params: { tipo },
    //   } = this.$route;

    //   this.urlValue = tipo;
    //    console.log(`set${this.urlValue}`);
    // },
    // alumnosLenght() {
    //   if(this.alumnos.alumnos){
    //     const lengths = this.alumnos.alumnos.reduce(
    //     (acc, alumno) => {
    //       if (alumno.Carrera.carrera === "TECNICATURA SEG. CIUDADANA") {
    //         acc.cadetesLength++;
    //       } else if (alumno.Carrera.carrera === "DIPLOMATURA SEG. CIUDADANA") {
    //         acc.aspitantesLength++;
    //       }
    //       return acc;
    //     },
    //     { cadetesLength: 0, aspitantesLength: 0 }
    //   )
    //   this.cadetesLength = lengths.cadetesLength
    //   this.aspitantesLength = lengths.aspitantesLength

    //   return lengths;
    //   }
      
    // },
   

  },
  computed: mapState({
    usuarios: (state) => state.d6.usuarios,
    
  }),
};
</script>

<style scoped>
.h3__list {
  height: 100%;
  display: flex;
  align-items: center;
}
</style>