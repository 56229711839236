import Vue from "vue";
import axios from 'axios'

const state = () => ({
  carreras: [],
  dataPolice:[],
  comisiones:[],
  usuarios:[],
  

  
});


const mutations = {
    setDatos(state, payload) {
        state[payload.key] = payload.value;
      },
    
 
};

const actions = {
  async setCarrera({ commit }) {
    return new Promise(async (resolve) => {
      try {
        let params = "/alumnos/carreras";
        const r = await Vue.prototype.$rest.get({ params });
           let mapResp = r.data.carreras.map((elem) => ({
          dataValue: elem.id_carrera,
          dataText: elem.carrera,
        }));  
        commit('setDatos',{
          key:'carreras',
          value:mapResp

        })
        resolve()
      } catch (err) {
        console.log(err);
      }
    });
  },
  async setComisiones({ commit }) {
    return new Promise(async (resolve) => {
      try {
        let params = "/alumnos/full_catalogos";
        const r = await Vue.prototype.$rest.get({ params });
           let mapResp = r.data.comisiones.map((elem) => ({
          dataValue: elem.id_comision,
          dataText: elem.comision,
          id_carrera:elem.id_carrera

        }));  
        commit('setDatos',{
          key:'comisiones',
          value:mapResp

        })
        resolve()
      } catch (err) {
        console.log(err);
      }
    });
  },

  getDataPolice({commit}){
    return new Promise(async(resolve)=>{
      try{
        let params = "/indice/personal";
        let r = await Vue.prototype.$rest.get({ params });
        console.log(r);
        commit('setDatos',{
          key:'dataPolice',
          value:r.data

        })
        resolve()
      }catch(err){
        console.log(err);
      }
    })

  },
  getUsuarios({commit}){
    return new Promise(async(resolve)=>{
      try{
        let params = "/indice/personal";
        let r = await Vue.prototype.$rest.get({ params });
        console.log(r);
        commit('setDatos',{
          key:'usuarios',
          value:r.data

        })
        resolve()
      }catch(err){
        console.log(err);
      }
    })

  },
  
}

const getters = {
      alumnos(state){
        return state.alumnos;
      },
      usuarios(state){
        return state.usuarios;
      },
}



export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
