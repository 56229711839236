<template>
  <div>
    <v-menu rounded="rounded" offset-x :close-on-content-click="true">
      <template v-slot:activator="{ attrs, on }">
        <v-btn color="green" icon v-bind="attrs" v-on="on">
          <v-icon size="30" color="green"
            >mdi-dots-vertical-circle-outline</v-icon
          >
        </v-btn>
      </template>

      <v-card>
        <v-card-actions>
          <v-btn icon @click="goToViewInfo()">
            <v-icon size="25" color="success">mdi-eye-outline</v-icon>
          </v-btn>
          <v-btn icon>
            <v-icon size="25" color="info">mdi-pencil</v-icon>
          </v-btn>
          <v-btn icon>
            <v-icon size="25" color="red">mdi-delete</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
export default {
  name: "ActionsMenuTable",
  props: {
    dataActions: {
      type: Object,
      default: () => ({}),
    },
    urlGoViewInfo: {
      type: String,
      default: "",
    },
    idGo: {
      type: String,
      default: "",
    },
  },
  methods: {
    goToViewInfo() {
      this.$router.push(
        `${this.$route.path}/${this.urlGoViewInfo}/${
          this.dataActions[this.idGo]
        }`
      );
    },
  },
};
</script>

<style lang="scss" scoped></style>
