import Vue from 'vue'
import Vuex from 'vuex'
import d1 from './modules/d1'
import user from './modules/user'


Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    d1,
    user
  },
  
  
})
